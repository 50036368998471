@forward "colors";
@use "colors" as *;

$font-weight-normal: 400;
$font-weight-medium: 500;
$font-weight-bold: 700;
$font-family-prompt: "Prompt";
$font-size: 1rem;
$button-font-size: 0.875rem;

$dark-dividers: rgba(black, 0.12);

$breakpoint-mobile-small: 445px;
$breakpoint-mobile: 576px;
$breakpoint-tablet: 768px;
$breakpoint-display: 992px;
$breakpoint-display-large: 1200px;

$padding-l: 1rem;
$padding-xl: 2rem;
$padding-xxl: 3rem;

$auth-background: "/assets/login-background.png";

// Specific css for excom
:host {
  .mat-mdc-chip.mat-mdc-chip-option.mat-mdc-chip-disabled.active {
    color: white !important;
    background: $color-accent-2;
  }

  // ::ng-deep app-clear-input {
  //   display: none !important;
  // }

  mat-sidenav {
    background: white !important;
  }

  mat-toolbar {
    background: white !important;
    color: $black !important;

    .logo {
      width: auto;
      height: 37px;
      display: flex;
      ::ng-deep svg {
        height: 24px;
        margin-top: 5px;
      }
    }
  }
}

.profile-button {
  color: $black !important;
}
