@use "sass:color";
@use "utilities";
@use "material" as *;
@use "variables" as *;

$color-secondary-50: color.mix($color-secondary, transparent, 50%);
// Plain CSS variables
:root {
  // Colors
  --color-primary: #{$color-primary};
  --color-primary-05: #{$color-primary-05};
  --color-primary-20: #{$color-primary-20};
  --color-primary-40: #{$color-primary-40};
  --color-primary-70: #{$color-primary-70};
  --color-secondary: #{$color-secondary};
  --color-secondary-70: #{$color-secondary-70};
  --color-secondary-50: #{color.mix($color-secondary, transparent, 50%)};
  --color-accent-1: #{$color-accent-1};
  --color-accent-2: #{$color-accent-2};
  --color-accent-2-70: #{$color-accent-2-70};
  --color-warn: #{$color-warn};
  --color-success: #{$color-success};
  --gray: #{$gray};
  --gray-dark: #{$gray-dark};
  // Breakpoints
  --breakpoint-mobile-small: #{$breakpoint-mobile-small};
  --breakpoint-mobile: #{$breakpoint-mobile};
  --breakpoint-tablet: #{$breakpoint-tablet};
  --breakpoint-display: #{$breakpoint-display};
  --breakpoint-display-large: #{$breakpoint-display-large};
}

@media print {
  .no-print {
    display: none !important;
  }
}

html,
body {
  width: 100%;
}

img {
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
  user-drag: none;
}

body {
  min-height: 100%;
  margin: 0;
  font-family: $font-family-prompt;
  font-display: swap;
  color: $black;
  overflow-x: hidden;
}

fieldset {
  margin: 0;
  padding: 0;
  border: none;
}

.flex {
  display: flex !important;
}
.flex-1 {
  flex: 1;
}

.nav {
  color: currentColor;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }

  &.active {
    font-weight: $font-weight-medium;
  }
}

.full-width-dialog {
  max-width: 1250px !important;

  &.campaign-creation,
  &.site-creation {
    max-width: 1450px !important;
  }

  @media (max-width: $breakpoint-mobile) {
    width: 100% !important;
    height: 100% !important;
    max-height: unset !important;
    & mat-dialog-container {
      // padding: 1rem;
      overflow-x: hidden;
    }
  }
}

.mdc-evolution-chip-set__chips {
  display: flex;
  align-items: center;
}

.mat-mdc-dialog-actions {
  padding-top: 1rem !important;
  display: flex !important;
  flex-wrap: wrap;
  min-height: unset !important;
  align-items: center;
  margin-bottom: unset !important;
  // bottom: 1rem;
  z-index: 99;
  padding: 1rem;
  margin: -1rem;
  justify-content: space-between !important;
  @media (max-width: $breakpoint-mobile) {
    position: absolute !important;
    background: white;
    width: calc(100% - 2rem);
    bottom: 1rem;
  }
}

.selectable-item {
  cursor: pointer;

  &:hover {
    background-color: $black-05;
  }

  &.selected {
    background-color: $black-10;
  }
}

mat-chip-option .mat-mdc-chip-action-label {
  display: flex;
  align-items: center;
}
.mat-mdc-chip.mat-mdc-standard-chip.mat-mdc-chip-disabled.active {
  opacity: 1;
  background-color: $color-secondary;
  font-weight: 500;
}

.selectable-card {
  cursor: pointer;

  &:hover {
    background-color: $black-05;
    border-color: $black-20;
  }

  &.selected {
    background-color: $black-10;
    border-color: $black-50;
  }
}
// No arrows on number input
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
  display: none;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.view-container {
  margin: 0 auto;
  // max-width: 1450px;

  // @media (max-width: $breakpoint-display-large) {
  //   max-width: 1250px;
  // }

  @media (max-width: $breakpoint-tablet) {
    max-width: unset;
    padding: 1rem !important;
    margin: 0;
  }
  @media (max-width: $breakpoint-mobile) {
    padding: 0.5rem !important;
  }
}

.mat-mdc-dialog-container {
  overflow: hidden;
  border-radius: 4px;
  .mdc-dialog__surface {
    padding: 1rem;
  }
}

.cdk-overlay-backdrop {
  backdrop-filter: blur(3px);
}
.mat-overlay-transparent-backdrop {
  backdrop-filter: unset;
}

th.mat-mdc-header-cell {
  color: $gray-dark;
  font-size: 11px;
}

td.mat-mdc-cell,
td.mat-mdc-footer-cell {
  font-weight: normal;
}

@media (max-width: $breakpoint-tablet) {
  th.mat-mdc-header-cell,
  td.mat-mdc-cell,
  td.mat-mdc-footer-cell {
    font-size: 11px;
  }
}

.mat-mdc-option {
  font-size: 1rem;
}

.grey-text {
  color: gray;
}

.space-between {
  justify-content: space-between;
}
.space-around {
  justify-content: space-around;
}

.ellipsis {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.row {
  display: flex;
  flex-flow: row;
  align-items: center !important;
  justify-content: space-between;
  @media (max-width: $breakpoint-tablet) {
    flex-flow: column;
  }
}

.center {
  align-items: center;
}

.justify-center {
  justify-content: center;
}

.content-center {
  align-content: center;
}

.hide-on-mobile {
  @media (max-width: $breakpoint-tablet) {
    display: none !important;
  }
}

.hide-on-display-large {
  @media (max-width: $breakpoint-display-large) {
    display: none !important;
  }
}

.text-center {
  text-align: center;
}
.text-end {
  text-align: end;
}

.text-right {
  text-align: right;
}

@media (max-width: $breakpoint-tablet) {
  ::ng-deep .mat-mdc-paginator-page-size {
    display: none !important;
  }
}

input:disabled {
  pointer-events: none;
}

.display-none {
  display: none !important;
}

body {
  .search-field {
    width: 320px;

    @media (max-width: $breakpoint-tablet) {
      width: 100%;
    }
  }

  .mat-mdc-card {
    // box-shadow: rgb(149 157 165 / 20%) 0px 8px 24px;
    border: none;
    border-radius: 4px;
    background: white;
    // border: $dark-dividers 1px solid;
  }

  .mat-mdc-card .mat-mdc-button,
  .mat-mdc-card-actions .mat-mdc-raised-button,
  .mat-card-actions .mat-mdc-outlined-button {
    font-weight: $font-weight-medium;

    .mat-icon {
      font-size: 1.25rem;
      width: 1.25rem;
      height: 1.25rem;
    }
  }

  .mat-mdc-card .mat-mdc-button {
    margin: 0;
  }
}
.navigate-back {
  display: flex;
  text-decoration: none;
  color: $black;
  transition: color ease-in-out 0.3s;
  justify-content: center;
  font-size: 0.9rem;
  cursor: pointer;
  mat-icon {
    font-size: 1.4rem;
    height: unset;
    width: unset;
  }
  &:hover {
    color: $black-50;
  }
}

.mdc-tab--active {
  background: $color-primary-20;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.without-bottom-border {
  border-bottom-right-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
  transform: height 0.6s ease;
}

.close-button {
  z-index: 9;
  top: 1rem;
  right: 1rem;
  position: absolute;
}

.mat-mdc-dialog-title {
  max-width: calc(100% - 50px);
  display: flex;
  align-items: center;
}

.card-shadow {
  box-shadow: rgb(149 157 165 / 20%) 0px 8px 24px !important;
}

[hidden] {
  display: none !important;
}

.app-loading {
  width: 100%;
  height: 100%;
  background: $black;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translate3d(0, 200%, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.fadeInUp {
  animation-duration: 1.2s;
  animation-name: fadeInUp;
}

.splash-loader {
  font-size: 10px;
  margin: 50px auto;
  text-indent: -9999em;
  width: 11em;
  height: 11em;
  border-radius: 50%;
  background: #ffffff;
  background: linear-gradient(to right, #ffffff 10%, rgba(255, 255, 255, 0) 42%);
  position: relative;
  animation: load3 1.4s infinite linear;
  transform: translateZ(0);
}

.splash-loader:before {
  width: 50%;
  height: 50%;
  background: #ffffff;
  border-radius: 100% 0 0 0;
  position: absolute;
  top: 0;
  left: 0;
  content: "";
}

.splash-loader:after {
  background: $black;
  width: 80%;
  height: 80%;
  border-radius: 50%;
  content: "";
  margin: auto;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

@keyframes load3 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.mdc-text-field--no-label:not(.mdc-text-field--outlined):not(.mdc-text-field--textarea) .mat-mdc-form-field-infix {
  padding-bottom: unset !important;
}

.clear-all-filters-button {
  display: flex;
  color: $color-warn;
  justify-content: flex-end;
  width: 100%;
  div {
    width: fit-content;
  }
  @media (max-width: $breakpoint-tablet) {
    margin-top: 0.5rem;
  }
}

.display-block {
  display: block !important;
}

.flex-end {
  justify-content: flex-end;
}

.color-warn {
  color: $color-warn !important;
}

.flex-row {
  display: flex;
  flex-flow: row;
}

.flex-column-wrap {
  display: flex;
  flex-flow: column;
  flex-wrap: wrap;
}

.flex-flow-column {
  flex-flow: column;
}

.flex-center-center {
  justify-content: center;
  align-items: center;
  align-content: center;
}
.no-max-width {
  max-width: 100% !important;
}

.default-chip {
  background: white !important;
  color: $gray-dark;
  border: solid 2px $gray-dark;
}
.rejected-chip {
  @extend .default-chip;
  color: $color-warn !important;
  border: solid 2px $color-warn;
}
.pending-chip {
  @extend .default-chip;
}
.accepted-chip {
  @extend .default-chip;
  color: $color-primary !important;
  border: solid 2px $color-primary;
}

.button-loader {
  left: 0;
  bottom: -2px;
  position: absolute !important;
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
}

::ng-deep mat-progress-bar {
  display: block;
  height: 4px;
  overflow: hidden;
  position: absolute;
  transition: opacity 250ms linear;
  width: 100%;
  left: 0;
  margin-top: 0.3em;
}

mark {
  background-color: $color-secondary-70;
  border-radius: 2px;
}

//mozilla specific styles for Date range picker and text box with search icon
@-moz-document url-prefix() {
  .mat-date-range-input {
    line-height: 25px;
  }

  .search-field {
    line-height: 20px;
  }
}

.secondary {
  color: $color-secondary;
}

.flex-gap {
  gap: 1rem;
}
.flex-gap-sm {
  gap: 0.5rem;
}

.align-items-end {
  align-items: end;
}
.align-items-start {
  align-items: start;
}

.justify-content-end {
  justify-content: end;
}

.flex-responsive {
  @media (max-width: $breakpoint-tablet) {
    flex-flow: column;
  }
}

mat-slide-toggle.hide-icon .mdc-switch__icons {
  display: none;
}

.mat-tab-group-label-wrapper {
  display: flex;
  align-items: center;
  gap: 5px;
}

.mat-mdc-autocomplete-panel .mdc-list-item__primary-text {
  white-space: nowrap !important;
  overflow: hidden;
  text-overflow: ellipsis;
}

.mdc-list-item__primary-text {
  display: flex;
  align-items: center;
}

mat-form-field {
  width: 100%;
}

.search-field,
.remove-subscript-wrapper {
  .mat-mdc-form-field-subscript-wrapper {
    display: none;
  }
}

body {
  .adtrac-card-primary-border {
    border: none;
    background: white;
    box-sizing: border-box;
    border-left: solid 4px $color-primary;
    border-radius: 4px;
    display: flex;
    flex-flow: column;
    justify-content: space-between;
  }

  .grey-card {
    background: color.mix(whitesmoke, white, 50%);
  }
}

.list__item__link {
  color: $color-primary;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
  &.disabled {
    pointer-events: none;
    text-decoration: none !important;
    color: unset;
  }
}

.disabled {
  pointer-events: none;
  text-decoration: none !important;
  color: unset !important;
}

mat-hint {
  color: $gray-dark;
  display: flex;
  gap: 0.5rem;
  align-items: center;
}
.mat-mdc-table .mat-mdc-row:hover,
.mat-mdc-table .mat-mdc-footer-row:hover {
  background-color: whitesmoke !important;
}
.selectable-item.selected {
  background-color: unset !important;
}
.warn {
  color: $color-warn !important;
}

.success {
  color: $color-success !important;
}

.info {
  color: $color-info !important;
}

.wrap-anywhere {
  overflow-wrap: anywhere;
}

.text-capitalize {
  text-transform: capitalize;
}

.list-no-style {
  list-style: none !important;
}

.campaign-canvas-container {
  width: 100%;
  position: relative;
}

.mat-h1 {
  overflow-wrap: anywhere;
}

.dropzone {
  border: 2px transparent;
  box-sizing: border-box;
}
.inline-input-edit {
  transition: all 0.2s ease-in-out;

  .mat-mdc-floating-label {
    // display: none !important;
  }
  .mdc-text-field {
    background: transparent !important;
  }
  .mdc-line-ripple {
    display: none !important;
  }
  #clr_input_btn_clear {
    display: none;
  }
}
.inline-input-edit-fixed {
  .mat-mdc-form-field-subscript-wrapper {
    height: 0;
  }
  .mdc-text-field {
    border-radius: 4px;
  }
}

.title-input {
  input {
    font-size: 2rem !important;
  }
}

.btn-color-success {
  &.mat-mdc-icon-button {
    --mdc-icon-button-icon-color: var(--color-success);
    --mat-icon-button-state-layer-color: var(--color-success);
    --mat-icon-button-ripple-color: color.mix(var(--color-success), transparent, 70%);
  }
}

.innactive-prefix {
  position: relative;
  top: -5.5px;
  color: rgba(0, 0, 0, 0.6);
}
.mat-mdc-form-field-text-prefix {
  color: rgba(0, 0, 0, 0.6);
}
.title-button-modifier {
  font-weight: normal !important;
  font-size: 1rem !important;
  &.mat-mdc-button[disabled] {
    color: unset !important;
  }
}

// ::ng-deep {
.standalone-chip.mat-mdc-standard-chip .mat-mdc-chip-action-label {
  cursor: pointer !important;
  display: flex !important;
  gap: 0.5rem;
  align-items: center;
  color: $color-primary !important;
  mat-icon {
    font-size: 1rem;
    width: 1rem;
    height: 1rem;
  }
}

.standalone-chip.mat-mdc-chip.mat-mdc-standard-chip {
  background-color: $color-primary-05 !important;
}

.cdk-textarea-autosize {
  overflow: hidden !important;
}
// }

.location-export {
  width: 100%;
  text-align: left;
}

.asset-card-dialog__campaign__media {
  .mat-mdc-dialog-container .mat-mdc-dialog-content {
    margin: 0px !important;
    padding: 0px !important;
    margin-top: 3rem !important;
  }
}
