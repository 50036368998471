@use "sass:color";

$black: #1d1d1b;
$black-02: color.mix($black, white, 2%);
$black-05: color.mix($black, white, 5%);
$black-10: color.mix($black, white, 10%);
$black-20: color.mix($black, white, 20%);
$black-50: color.mix($black, white, 50%);

$color-primary: #002c77;
$color-primary-05: color.mix($color-primary, rgba(255, 255, 255, 0.25), 5%);
$color-primary-20: color.mix($color-primary, white, 20%);
$color-primary-40: color.mix($color-primary, transparent, 40%);
$color-primary-70: color.mix($color-primary, transparent, 70%);
$color-secondary: #83dbeeff;
$color-secondary-70: color.mix($color-secondary, transparent, 70%);
$color-accent-1: #016d9f;
$color-accent-2: #cd1719;
$color-accent-2-70: color.mix($color-accent-2, transparent, 70%);
$color-warn: #f44336;
$color-success: #66bb6a;
$color-info: #dcd531;
$gray: #c1c1c1;
$gray-dark: #8e8e8d;
