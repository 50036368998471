@use "variables" as *;
@use "sass:math";
@use "sass:map";

$spacer: 1rem !default;
$spacers: () !default;

$spacers: map.merge(
  (
    0: 0,
    1: (
      $spacer * 0.25
    ),
    2: (
      $spacer * 0.5
    ),
    3: $spacer,
    4: (
      $spacer * 1.5
    ),
    5: (
      $spacer * 3
    )
  ),
  $spacers
);

@each $prop, $abbrev in (margin: m, padding: p) {
  @each $size, $length in $spacers {
    .#{$abbrev}-#{$size} {
      #{$prop}: $length !important;
    }

    .#{$abbrev}t-#{$size},
    .#{$abbrev}y-#{$size} {
      #{$prop}-top: $length !important;
    }

    .#{$abbrev}r-#{$size},
    .#{$abbrev}x-#{$size} {
      #{$prop}-right: $length !important;
    }

    .#{$abbrev}b-#{$size},
    .#{$abbrev}y-#{$size} {
      #{$prop}-bottom: $length !important;
    }

    .#{$abbrev}l-#{$size},
    .#{$abbrev}x-#{$size} {
      #{$prop}-left: $length !important;
    }
  }
}

$total-columns: 12;
@for $i from 1 through ($total-columns - 1) {
  .col-#{$i} {
    $calPer: math.percentage(calc($i / $total-columns));
    width: calc($calPer - 0.5rem); // Converts fraction to percentage
    @media (max-width: #{$breakpoint-tablet}) {
      width: 100%;
    }
  }
}
// .col-1 -> 8.3333%;
// .col-2 -> 16.6667%;
// .col-3 -> 25%;
// .col-4 -> 33.3333%;
// .col-5 -> 41.6667%;
// .col-6 -> 50%;
// .col-7 -> 58.3333%;
// .col-8 -> 66.6667%;
// .col-9 -> 75%;
// .col-10 -> 83.3333%;
// .col-11 -> 91.6667%;

.col-12 {
  width: 100%;
}

$custom-widths: (20);
@each $width in $custom-widths {
  .w-#{$width} {
    width: calc(math.percentage(calc($width / 100)) - 0.5rem); // Converts fraction to percentage
    @media (max-width: #{$breakpoint-tablet}) {
      width: 100%;
    }
  }
}

.h-100 {
  height: 100%;
}

// add padding for divider '-' for the start- and end-times
// and the target group start- and eng-ages
.padding-top-divider {
  padding-top: 3em;
}

.border-left {
  border-left: $dark-dividers 1px solid;
}

.border-right {
  border-right: $dark-dividers 1px solid;
}

.border-top {
  border-top: $dark-dividers 1px solid;
}

.border-bottom {
  border-bottom: $dark-dividers 1px solid;
}

.text-r {
  text-align: right !important;
}

.text-bold {
  font-weight: $font-weight-bold;
}

@for $i from 1 through 12 {
  .flex-#{$i} {
    flex: $i;
  }
}
